import React from "react";
import { LpRpo01PageLayout } from "~/molecules/lp/rpo/01/LpRpo01PageLayout";
import { LpRpo01PageHero } from "~/molecules/lp/rpo/01/LpRpo01PageHero";
import { LpRpo01PageInPageLinks } from "~/molecules/lp/rpo/01/LpRpo01PageInPageLinks";
import { LpRpo01PageBanner } from "~/molecules/lp/rpo/01/LpRpo01PageBanner";
import { LpRpo01PageCompanyLogos } from "~/molecules/lp/rpo/01/LpRpo01PageCompanyLogos";
import { LpRpo01PageProblem } from "~/molecules/lp/rpo/01/LpRpo01PageProblem";
import { LpRpo01PageFeatures } from "~/molecules/lp/rpo/01/LpRpo01PageFeatures";
import { LpRpo01PageDocumentDownloadGuid } from "~/molecules/lp/rpo/01/LpRpo01PageDocumentDownloadGuide";
import { LpRpo01PageCasesOnTop } from "~/molecules/lp/rpo/01/LpRpo01PageCases";
import { LpRpo01PageSupportStep } from "~/molecules/lp/rpo/01/LpRpo01PageSupportStep";
import { LpRpo01PageQuestions } from "~/molecules/lp/rpo/01/LpRpo01PageQuestions";
import { LpRpo01PagePrice } from "~/molecules/lp/rpo/01/LpRpo01PagePrice";
import { LpRpo01PageContact } from "~/molecules/lp/rpo/01/LpRpo01PageContact";

type Props = {};

const LpRpo01: React.FC<Props> = () => (
  <>
    <LpRpo01PageBanner />
    <LpRpo01PageLayout
      title="採用課題解決ならRefcome（リフカム）の成果報酬型リファラル採用支援"
      ogpDescription="Refcomeは社内への展開から定着までリファラル採用活動を代行する成果報酬型リファラル採用支援サービスを提供しています。業種・業態・採用職種を問わず850社を超える取引実績で得たノウハウをもとに組織課題や状況に合わせてリファラル採用を成功に導く支援を行います。"
      noindex={true}
    >
      <LpRpo01PageHero />
      <LpRpo01PageInPageLinks />
      <LpRpo01PageCompanyLogos />
      <LpRpo01PageProblem />
      <LpRpo01PageFeatures />
      <LpRpo01PageDocumentDownloadGuid />
      <LpRpo01PageCasesOnTop />
      <LpRpo01PagePrice />
      <LpRpo01PageSupportStep />
      <LpRpo01PageContact />
      <LpRpo01PageQuestions />
    </LpRpo01PageLayout>
  </>
);

export default LpRpo01;
