import React from "react";
import "swiper/css";
import ApcomPng from "~/images/companyLogo/apcom.png";
import CyberagentPng from "~/images/companyLogo/cyberagent.png";
import DenaPng from "~/images/companyLogo/dena.png";
import EbaraPng from "~/images/companyLogo/ebara.png";
import KeizaisanngyosyoPng from "~/images/companyLogo/nihonkeisannsyou.png";
import LitalicoPng from "~/images/companyLogo/litalico.png";
import MarubinePng from "~/images/companyLogo/marubeni.png";
import NihonsoukenPng from "~/images/companyLogo/nihonsouken.png";
import SansanPng from "~/images/companyLogo/sansan.png";
import TechnoprodesignPng from "~/images/companyLogo/technoprodesign.png";
import ToyoengineeringPng from "~/images/companyLogo/toyoengineering.png";
import YayoiPng from "~/images/companyLogo/yayoi.png";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

export const LpRpo01PageCompanyLogos: React.FC = () => (
  <>
    <div className="titleWrapper">
      <span className="title">業種・業態・採用職種を問わずに850社以上が導入</span>
    </div>
    <div className="companyLogs">
      <div className="scroll-container">
        {/* NOTE: ロゴが途中で途切れないようにScrollContentは２つ繋げる */}
        <ScrollContent />
        <ScrollContent />
      </div>
    </div>

    <style jsx>{`
      .titleWrapper {
        margin-top: 48px;
        padding: 0 24px;
        text-align: center;
      }
      .title {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 2px;
        background: radial-gradient(
            circle at calc(100% - 4px) calc(100% - 4px),
            ${colors.subOrange} 4px,
            transparent 4px
          ),
          radial-gradient(circle at 4px calc(100% - 4px), ${colors.subOrange} 4px, transparent 4px),
          linear-gradient(to bottom, ${colors.white} calc(100% - 8px), transparent calc(100% - 8px)),
          linear-gradient(
            to right,
            transparent 4px,
            ${colors.subOrange} 4px,
            ${colors.subOrange} calc(100% - 4px),
            transparent calc(100% - 4px)
          );
      }
      .companyLogs {
        margin-top: 40px;
        margin-bottom: 24px;
      }
      .scroll-container {
        display: flex;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
      }
      @media (${breakPoints.sp}) {
        .titleWrapper {
          margin-top: 32px;
          line-height: 36px;
        }
        .title {
          font-size: 24px;
          background: linear-gradient(transparent 70%, ${colors.subOrange} 50%);
        }
        .companyLogs {
          margin-top: 32px;
        }
      }
    `}</style>
  </>
);

// NOTE: ロゴは高さ40px、中央寄せのものを作成して利用する
const ScrollContent = () => (
  <>
    <div className="scroll-content">
      <div className="item">
        <img src={ApcomPng} alt="" />
      </div>
      <div className="item">
        <img src={CyberagentPng} alt="" />
      </div>
      <div className="item">
        <img src={DenaPng} alt="" />
      </div>
      <div className="item">
        <img src={EbaraPng} alt="" />
      </div>
      <div className="item">
        <img src={KeizaisanngyosyoPng} alt="" />
      </div>
      <div className="item">
        <img src={LitalicoPng} alt="" />
      </div>
      <div className="item">
        <img src={MarubinePng} alt="" />
      </div>
      <div className="item">
        <img src={NihonsoukenPng} alt="" />
      </div>
      <div className="item">
        <img src={SansanPng} alt="" />
      </div>
      <div className="item">
        <img src={TechnoprodesignPng} alt="" />
      </div>
      <div className="item">
        <img src={ToyoengineeringPng} alt="" />
      </div>
      <div className="item">
        <img src={YayoiPng} alt="" />
      </div>
    </div>

    <style jsx>{`
      .scroll-content {
        animation: scroll 24s linear infinite;
        display: flex;
        height: 60px;
      }
      .item {
        align-items: center;
        display: flex;
        margin: 0 20px;
      }
      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-100%);
        }
      }
    `}</style>
  </>
);
