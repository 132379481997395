import React, { FC, useEffect, useState } from "react";
import BannerImage from "~/images/LpRpo01PageBanner.png";
import { Link } from "gatsby";
import { FiDownload } from "@react-icons/all-files/fi/FiDownload";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { useLocation } from "@reach/router";

type BannerType = {};

export const LpRpo01PageBanner: FC<BannerType> = () => {
  const [isClosedBanner, setIsClosedBanner] = useState(false);
  const [isShowBanner, setIsShowBanner] = useState(false);
  const location = useLocation();

  const toggleVisibility = () => {
    if (isClosedBanner) return;
    window.scrollY > 500 && setIsShowBanner(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, [toggleVisibility]);

  const closeBannerHandle = () => {
    setIsShowBanner(false);
    setIsClosedBanner(true);
  };

  if (!isShowBanner) return null;

  return (
    <>
      <div className="banner">
        <div className="closeButton" onClick={closeBannerHandle}>
          <IoMdClose style={{ fontSize: "20px", color: `${colors.white}` }} />
        </div>
        <img src={BannerImage} className="bannerImage" />
        <div className="main">
          <span className="description">Refcome成果報酬型リファラル採⽤⽀援サービス資料</span>
          <span className="bannerButton">
            <Link to={`/lp/rpo/01${location.search}#contact`}>
              <span className="bannerButtonText">資料をダウンロード</span>
              <FiDownload style={{ fontSize: "16px", marginLeft: "4px", color: `${colors.white}` }} />
            </Link>
          </span>
        </div>
      </div>

      <style jsx>{`
        .banner {
          position: fixed;
          bottom: 0;
          right: 0;
          max-width: 280px;
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
          z-index: 100;
          margin: 18px;
          background-color: ${colors.white};
          animation: fadeIn 1s ease-in-out forwards;
        }
        .closeButton {
          background-color: ${colors.black};
          position: absolute;
          top: -10px;
          right: -10px;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .closeButton:hover {
          cursor: pointer;
        }
        .bannerImage {
          width: 100%;
        }
        .main {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 28px 16px;
          gap: 16px;
        }

        .description {
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          line-height: 24px;
        }

        .bannerButton {
          display: block;
        }

        .bannerButton :global(a) {
          display: block;
          position: relative;
          align-items: center;
          background-image: ${colors.brandGradient};
          background-color: ${colors.brand};
          border-radius: 50px;
          display: flex;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          justify-content: center;
          padding: 6px 38px;
          height: 36px;
          position: relative;
          text-align: center;
          text-decoration: none;
          transition: all 0.2s ease-out;
        }

        .bannerButton :global(a:hover) {
          opacity: 0.85;
          text-decoration: none;
          transform: translate3d(0, -1px, 0);
        }

        .bannerButton :global(a:before) {
          background-color: ${colors.brandGradient};
          border-radius: 50px;
          content: "";
          left: 1px;
          height: calc(100% - 2px);
          position: absolute;
          top: 1px;
          width: calc(100% - 2px);
        }

        .bannerButtonText {
          color: ${colors.white};
          display: block;
          position: relative;
          z-index: 1;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @media (${breakPoints.sp}) {
          .banner {
            display: none;
          }
        }
      `}</style>
    </>
  );
};
