import React, { FC } from "react";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { DocumentForm } from "~/organisms/DocumentForm";

type ContactType = {};

export const LpRpo01PageContact: FC<ContactType> = () => {
  return (
    <>
      <div id="contact" className="contactSection" />
      <div className="container">
        <div className="sectionLabelWrapper">
          <span className="sectionLabel">Contact</span>
        </div>
        <div className="titleWrapper">
          <span className="title">資料ダウンロード・お問い合わせ</span>
        </div>
        <div className="form">
          <DocumentForm
            formspreeEndpoint="https://formspree.io/f/mlddadny"
            thanksPagePath="/lp/rpo/01/thanks"
            gtmEventName="lp_rpo_01_form_partially_filled"
          />
        </div>
      </div>

      <style jsx>{`
        .contactSection {
          content: "";
          display: block;
          height: 90px;
          margin-top: -90px;
          visibility: hidden;
        }

        .container {
          padding: 48px 24px;
          background-color: ${colors.lightOrange};
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
        }
        .sectionLabel {
          color: ${colors.brand};
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 1.75px;
        }
        .title {
          font-size: 32px;
          font-weight: bold;
          letter-spacing: 2px;
          background: radial-gradient(
              circle at calc(100% - 4px) calc(100% - 4px),
              ${colors.subOrange} 4px,
              transparent 4px
            ),
            radial-gradient(circle at 4px calc(100% - 4px), ${colors.subOrange} 4px, transparent 4px),
            linear-gradient(to bottom, ${colors.lightOrange} calc(100% - 8px), transparent calc(100% - 8px)),
            linear-gradient(
              to right,
              transparent 4px,
              ${colors.subOrange} 4px,
              ${colors.subOrange} calc(100% - 4px),
              transparent calc(100% - 4px)
            );
        }
        .form {
          width: 600px;
          margin-top: 48px;
        }
        @media (${breakPoints.sp}) {
          .titleWrapper {
            text-align: center;
          }
          .title {
            font-size: 28px;
            background: linear-gradient(transparent 70%, ${colors.subOrange} 50%);
          }
          .form {
            width: 100%;
            margin-top: 40px;
          }
        }
      `}</style>
    </>
  );
};
