import React from "react";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { DocumentDownloadButton } from "~/organisms/DocumentDownloadButton";
import { TelephoneNumberPanel } from "~/organisms/TelephoneNumberPanel";
import { useLocation } from "@reach/router";

export const LpRpo01PagePrice: React.FC<{}> = () => {
  const location = useLocation();

  return (
    <>
      <div id="price" className="priceSection" />
      <div className="container">
        <div className="sectionLabelWrapper">
          <span className="sectionLabel">Price</span>
        </div>
        <div className="titleWrapper">
          <span className="title">ご利用料金</span>
        </div>
        <div className="description">
          <span>
            月額固定プランやリファラルツール利用のみのプランもご用意できます。
            <br className="descriptionBr" />
            ご状況を伺ったうえで最適なプランをご案内いたします。
            <br />
            お気軽にお問い合わせください。
          </span>
        </div>
        <div className="pricingCard">
          <div className="costHeader">
            <span className="costLabel">
              初期費用
              <br />
              運用費用
            </span>
            <div className="costValue">
              <span className="highlight">0</span>
              <span className="yen">円</span>
            </div>
          </div>
          <div className="costDetail">
            <span className="constDetailTitle">成果報酬費用</span>
            <br />
            採用1人あたりにつき年収の20%
          </div>
        </div>
        <div className="downloadSection">
          <DocumentDownloadButton to={`/lp/rpo/01${location.search}#contact`} />
          <TelephoneNumberPanel />
        </div>
      </div>

      <style jsx>{`
        .priceSection {
          content: "";
          display: block;
          height: 90px;
          margin-top: -90px;
          visibility: hidden;
        }
        .container {
          padding: 48px 24px;
          background-color: ${colors.lightOrange};
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
        }
        .sectionLabel {
          color: ${colors.brand};
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 1.75px;
        }
        .title {
          font-size: 32px;
          font-weight: bold;
          letter-spacing: 2px;
          background: radial-gradient(
              circle at calc(100% - 4px) calc(100% - 4px),
              ${colors.subOrange} 4px,
              transparent 4px
            ),
            radial-gradient(circle at 4px calc(100% - 4px), ${colors.subOrange} 4px, transparent 4px),
            linear-gradient(to bottom, ${colors.lightOrange} calc(100% - 8px), transparent calc(100% - 8px)),
            linear-gradient(
              to right,
              transparent 4px,
              ${colors.subOrange} 4px,
              ${colors.subOrange} calc(100% - 4px),
              transparent calc(100% - 4px)
            );
        }
        .description {
          font-size: 18px;
          display: flex;
          justify-content: center;
          text-align: center;
          color: ${colors.lightText};
          margin-top: 28px;
          line-height: 1.6;
        }
        .pricingCard {
          margin-top: 24px;
          background: ${colors.white};
          border-radius: 12px;
          padding: 32px 56px;
          width: 330px;
          text-align: center;
          border: 4px solid ${colors.brand};
        }
        .costHeader {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 24px;
          position: relative;
        }
        .costLabel {
          position: relative;
          top: 8px;
          background: ${colors.black};
          color: ${colors.white};
          border-radius: 8px;
          padding: 10px 12px;
          font-size: 14px;
          line-height: 1.5;
          font-weight: 500;
        }
        .costValue {
          position: relative;
          font-size: 24px;
          font-weight: bold;
          display: flex;
          align-items: flex-end;
        }
        .costValue::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 10px;
          height: 8px;
          background-color: ${colors.subOrange};
          border-radius: 2px;
        }
        .costValue .highlight {
          position: relative;
          top: -10px;
          font-size: 96px;
          font-weight: bold;
          line-height: 1;
          margin-right: 4px;
          display: inline-block;
          transform: scale(1.1, 1.3);
        }
        .costValue .yen {
          z-index: 1;
        }
        .constDetailTitle {
          font-weight: bold;
        }
        .costDetail {
          font-size: 14px;
          line-height: 1.5;
          margin-top: 4px;
        }
        .downloadSection {
          margin-top: 32px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 24px;
        }
        @media (${breakPoints.sp}) {
          .titleWrapper {
            text-align: center;
          }
          .title {
            font-size: 28px;
            background: linear-gradient(transparent 70%, ${colors.subOrange} 50%);
          }
          .description {
            font-size: 14px;
          }
          .descriptionBr {
            display: none;
          }
          .priceBlock {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};
