import React from "react";
import LpRpo01PageProblemImage from "~/images/LpRpo01PageProblemImage.png";
import { MdCheck } from "@react-icons/all-files/md/MdCheck";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

export const LpRpo01PageProblem: React.FC<{}> = () => {
  const mdCheckIconStyle = {
    width: `24px`,
    height: `24px`,
    color: `${colors.darkBlue}`,
  };

  return (
    <>
      <div id="aboutReferral" className="aboutReferralSession" />
      <div className="container">
        <div className="sectionLabelWrapper">
          <span className="sectionLabel">Problem</span>
        </div>
        <div className="titleWrapper">
          <span className="title">
            とにかく人がいない
            <span className="period">...。</span>
            <br className="titleBr" />
            非常に困っている
          </span>
        </div>
        <div className="problemAndImageBox">
          <div className="problemImageWrapper">
            <img src={LpRpo01PageProblemImage} style={{ width: "100%" }} />
          </div>
          <div className="problemList">
            <div className="problemWrapper">
              <MdCheck style={mdCheckIconStyle} />
              <span className="problem">計画に全く追いつかない</span>
            </div>
            <div className="problemWrapper">
              <MdCheck style={mdCheckIconStyle} />
              <span className="problem">スカウトや求人から人が集まらない</span>
            </div>
            <div className="problemWrapper">
              <MdCheck style={mdCheckIconStyle} />
              <span className="problem">新しいことをやる余力がない</span>
            </div>
            <div className="problemWrapper">
              <MdCheck style={mdCheckIconStyle} />
              <span className="problem">採用リソースが足りない</span>
            </div>
            <div className="problemWrapper">
              <MdCheck style={mdCheckIconStyle} />
              <span className="problem">面談数をもっと増やしたい</span>
            </div>
          </div>
        </div>
        <div className="triangle" />
      </div>
      <style jsx>{`
        .aboutReferralSession {
          content: "";
          display: block;
          height: 90px;
          margin-top: -90px;
          visibility: hidden;
        }
        .container {
          padding: 48px 24px;
          background-color: ${colors.lightOrange};
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
        }
        .sectionLabel {
          color: ${colors.brand};
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 1.75px;
        }
        .title {
          font-size: 32px;
          font-weight: bold;
          letter-spacing: 2px;
          background: radial-gradient(
              circle at calc(100% - 4px) calc(100% - 4px),
              ${colors.subOrange} 4px,
              transparent 4px
            ),
            radial-gradient(circle at 4px calc(100% - 4px), ${colors.subOrange} 4px, transparent 4px),
            linear-gradient(to bottom, ${colors.lightOrange} calc(100% - 8px), transparent calc(100% - 8px)),
            linear-gradient(
              to right,
              transparent 4px,
              ${colors.subOrange} 4px,
              ${colors.subOrange} calc(100% - 4px),
              transparent calc(100% - 4px)
            );
        }
        .problemAndImageBox {
          display: flex;
          margin-top: 40px;
          gap: 56px;
        }
        .problemImageWrapper {
          width: 400px;
          height: 350px;
        }
        .problemList {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
        .problemWrapper {
          max-width: 400px;
          height: 56px;
          padding: 16px;
          display: flex;
          align-items: center;
          gap: 16px;
          background-color: ${colors.white};
          border-radius: 6px;
          border-right: 1px solid ${colors.orange};
          border-bottom: 1px solid ${colors.orange};
        }
        .checkIcon {
          width: 24px;
          height: 24px;
          color: ${colors.darkBlue};
        }
        .problem {
          font-size: 16px;
          font-weight: bold;
          color: ${colors.darkText};
          letter-spacing: 1px;
        }
        .triangle {
          position: absolute;
          top: 100%;
          width: 0;
          height: 0;
          border-style: solid;
          border-right: 120px solid transparent;
          border-left: 120px solid transparent;
          border-top: 50px solid ${colors.lightOrange};
          border-bottom: 0;
        }
        @media (${breakPoints.pc}) {
          .titleBr {
            display: none;
          }
        }
        @media (${breakPoints.tablet}) {
          .titleWrapper {
            text-align: center;
          }
          .period {
            display: none;
          }
        }
        @media (${breakPoints.sp}) {
          .problemAndImageBox {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-items: center;
            align-items: center;
          }
          .titleWrapper {
            text-align: center;
          }
          .title {
            font-size: 28px;
            background: linear-gradient(transparent 70%, ${colors.subOrange} 50%);
          }
          .period {
            display: none;
          }
          .problemList {
            width: 100%;
            justify-items: center;
            align-items: center;
          }
          .problemImageWrapper {
            order: 1;
            width: 100%;
            height: auto;
          }
          .problemWrapper {
            width: 100%;
            height: auto;
          }
          .problem {
            line-height: 20px;
          }
          .triangle {
            display: none;
          }
        }
      `}</style>
    </>
  );
};
