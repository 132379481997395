import React from "react";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { DocumentDownloadButton } from "~/organisms/DocumentDownloadButton";
import LpRpo01PageRewards from "~/images/LpRpo01PageRewards.png";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";

export const LpRpo01PageHero: React.FC = () => {
  const location = useLocation();
  const { LpRpo01PageMainVisual, LpRpo01PageMainVisualSp } = useStaticQuery<GatsbyTypes.LpRpo01PageHeroQuery>(graphql`
    query LpRpo01PageHero {
      LpRpo01PageMainVisual: file(relativePath: { eq: "LpRpo01PageMainVisual.png" }) {
        publicURL
      }
      LpRpo01PageMainVisualSp: file(relativePath: { eq: "LpRpo01PageMainVisualSp.png" }) {
        publicURL
      }
      LpRpo01PageRewards: file(relativePath: { eq: "LpRpo01PageRewards.png" }) {
        childImageSharp {
          gatsbyImageData(width: 450, quality: 100, layout: FIXED)
        }
      }
    }
  `);

  return (
    <>
      <div className="container">
        <div className="topTitleWrapper">
          <span className="topTitle">固定費も人事リソースもゼロ</span>
        </div>
        <div className="mainTitleWrapper">
          <span className="mainTitle">
            社内メンバーを
            <br />
            転職エージェントに変える
          </span>
        </div>
        <div className="bottomTitleWrapper">
          <span className="bottomTitle">成果報酬型リファラル採用支援</span>
        </div>
        <div className="rewordsAndButton">
          <div className="rewords">
            <img src={LpRpo01PageRewards} style={{ width: "100%" }} />
          </div>
          <DocumentDownloadButton to={`/lp/rpo/01${location.search}#contact`} />
        </div>
      </div>

      <style jsx>{`
        .container {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 100%;
          margin-top: 92px;
          padding: 3.25vw 4vw;
          background-image: url(${LpRpo01PageMainVisual?.publicURL});
          background-size: cover;
          background-position: right;
          background-repeat: no-repeat;
          min-height: 500px;
          aspect-ratio: 1280 / 500;
        }
        .topTitleWrapper {
        }
        .topTitle {
          font-size: 2vw;
          font-weight: 500;
          background: linear-gradient(transparent 60%, ${colors.lightOrange} 30%);
          letter-spacing: 1px;
        }
        .mainTitleWrapper {
          margin-top: 1.5vw;
        }
        .mainTitle {
          font-size: 3.5vw;
          font-weight: 500;
          line-height: 4.5vw;
          letter-spacing: 1px;
        }
        .bottomTitleWrapper {
          margin-top: 1.5vw;
        }
        .bottomTitle {
          font-size: 1.5vw;
          background: linear-gradient(transparent 60%, ${colors.lightOrange} 30%);
          color: ${colors.lightText};
          letter-spacing: 1px;
        }
        .rewordsAndButton {
          align-items: center;
          width: 100%;
          display: flex;
          gap: 17vw;
          height: auto;
          margin-top: 48px;
        }
        .rewords {
          height: 150px;
          width: 450px;
        }
        @media (min-width: 1000px) and (max-width: 1200px) {
          .topTitle {
            font-size: 3vw;
          }
          .mainTitleWrapper {
            margin-top: 1vw;
          }
          .mainTitle {
            font-size: 4.5vw;
          }
          .bottomTitleWrapper {
            margin-top: 1vw;
          }
          .bottomTitle {
            font-size: 2vw;
          }
          .rewordsAndButton {
            margin-top: 24px;
            gap: 10vw;
          }
        }
        @media (${breakPoints.tablet}) {
          .topTitle {
            font-size: 3vw;
          }
          .mainTitleWrapper {
            margin-top: 1vw;
          }
          .mainTitle {
            font-size: 4.5vw;
          }
          .bottomTitleWrapper {
            margin-top: 1vw;
          }
          .bottomTitle {
            font-size: 2vw;
          }
          .rewordsAndButton {
            margin-top: 24px;
          }
          .rewords {
            display: none;
          }
        }
        @media (${breakPoints.sp}) {
          .container {
            margin-top: 72px;
            background-image: url(${LpRpo01PageMainVisualSp?.publicURL});
            background-position: top;
            height: 500px;
            padding-top: 60%;
            padding-bottom: 40px;
          }
          .topTitle {
            font-size: 16px;
          }
          .mainTitle {
            font-size: 26px;
            line-height: 42px;
          }
          .bottomTitle {
            display: none;
          }
          .rewords {
            display: none;
          }
        }
      `}</style>
    </>
  );
};
