import React, { FC } from "react";
import { CallToActionButton } from "~/atoms/CallToActionButton";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { IoMdArrowForward } from "@react-icons/all-files/io/IoMdArrowForward";

type DocumentDownloadButtonType = {
  to: string;
};

export const DocumentDownloadButton: FC<DocumentDownloadButtonType> = ({ to }) => {
  return (
    <>
      <div className="button">
        <CallToActionButton to={to} backgroundColor={colors.brandGradient} textColor={colors.white} size={`100%`}>
          <div className="buttonItems">
            <div className="buttonTexts">
              <text className="buttonSubText">リファラル採用がよくわかる資料</text>
              <br />
              <text className="buttonMainText">資料をダウンロード</text>
            </div>
            <IoMdArrowForward style={{ fontSize: 24 }} />
          </div>
        </CallToActionButton>
      </div>

      <style jsx>
        {`
          .button {
            width: 330px;
          }
          .buttonItems {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 16px;
          }
          .buttonTexts {
            margin-right: 12px;
          }
          .buttonSubText {
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.8px;
          }
          .buttonMainText {
            font-size: 24px;
            font-weight: bold;
          }
          @media (${breakPoints.sp}) {
            .button {
              width: 280px;
            }
            .buttonItems {
              margin-left: 12px;
            }
            .buttonTexts {
              line-height: 20px;
              margin-right: 10px;
            }
            .buttonSubText {
              font-size: 12px;
              letter-spacing: 0.3px;
            }
            .buttonMainText {
              font-size: 18px;
              letter-spacing: 2px;
            }
          }
        `}
      </style>
    </>
  );
};
