import React, { ReactNode } from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

type Props = {
  number: string;
  subTitle?: string;
  title: ReactNode;
  description: string;
  imageData?: IGatsbyImageData;
  direction?: "ltr" | "rtl";
};

export const Feature: React.FC<Props> = ({ number, direction = "rtl", title, subTitle, description, imageData }) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          align-items: center;
          display: flex;
          flex-direction: ${direction === "rtl" ? "row-reverse" : "row"};
          margin: 100px auto;
        }
        .imageWrapper {
          margin: 0 0 0 ${direction === "rtl" ? "10%" : 0};
          width: 45%;
        }
        .textWrapper {
          align-items: center;
          display: flex;
          flex-direction: column;
          margin: 0 0 0 ${direction === "ltr" ? "10%" : 0};
          width: 45%;
        }
        .numberAndTitleWrapper {
          padding-bottom: 24px;
          border-bottom: solid 1px ${colors.brand};
          width: 100%;
        }
        .numberAndTitle {
          display: flex;
        }
        .number {
          font-size: 42px;
          color: ${colors.brand};
          font-weight: bold;
        }
        .titleWrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          line-height: 38px;
          margin-left: 20px;
        }
        .subTitle {
          font-size: 18px;
          color: ${colors.darkerText};
        }
        .title {
          color: ${colors.darkerText};
          font-size: 38px;
        }
        .descriptionWrapper {
          margin-top: 24px;
        }
        .description {
          color: ${colors.lightText};
          font-size: 15px;
          line-height: 1.8;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            align-items: flex-start;
            flex-direction: column;
            margin: 0 0 50px;
            max-width: 100%;
          }
          .imageWrapper {
            margin: 0 auto;
            padding: 0 30px;
            width: 90%;
          }
          .textWrapper {
            margin: 0;
            padding: 20px 30px;
            width: 100%;
            order: -1;
          }
          .numberAndTitleWrapper {
            padding-bottom: 0;
          }
          .subTitle {
            display: none;
          }
          .title {
            font-size: 24px;
          }
          .descriptionWrapper {
            margin-top: 12px;
          }
        }
      `}</style>
      <div className="wrapper">
        {imageData && (
          <div className="imageWrapper">
            <GatsbyImage image={imageData} alt="" />
          </div>
        )}
        <div className="textWrapper">
          <div className="numberAndTitleWrapper">
            <div className="numberAndTitle">
              <div className="number">{number}</div>
              <div className="titleWrapper">
                {subTitle && <div className="subTitle">{subTitle}</div>}
                <h2 className="title">{title}</h2>
              </div>
            </div>
          </div>
          <div className="descriptionWrapper">
            <p className="description">{description}</p>
          </div>
        </div>
      </div>
    </>
  );
};
