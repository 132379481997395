import React, { FC } from "react";
import { colors } from "~/utils/colors";
import ArrowPng from "~/images/arrow.png";
import { breakPoints } from "~/utils/variables";

type SupportStepType = {};

export const LpRpo01PageSupportStep: FC<SupportStepType> = () => {
  return (
    <>
      <div className="container">
        <div className="titleWrapper">
          <hr className="divider" />
          <div className="title">
            <span>
              お問い合わせから
              <br className="titleBr" />
              支援開始までの流れ
            </span>
          </div>
          <hr className="divider" />
        </div>
        <div className="steps">
          <div className="step">
            <div className="numberWrapper">
              <div className="numberLabel">
                <span>STEP</span>
              </div>
              <div className="number">
                <span>01</span>
              </div>
            </div>
            <div className="descriptionWrapper">
              <div className="descriptionTitle">
                <span>課題をヒアリング</span>
              </div>
              <div className="description">
                <span>
                  お問い合わせ後、当社営業担当より、メールまたは電話による日程調整の上、課題のヒアリングとサービス概要をご紹介します。
                </span>
              </div>
            </div>
          </div>
          <div className="arrowWrapper">
            <div className="arrow">
              <img src={ArrowPng} />
            </div>
          </div>
          <div className="step">
            <div className="numberWrapper">
              <div className="numberLabel">
                <span>STEP</span>
              </div>
              <div className="number">
                <span>02</span>
              </div>
            </div>
            <div className="descriptionWrapper">
              <div className="descriptionTitle">
                <span>ご提案</span>
              </div>
              <div className="description">
                <span>貴社の課題にあった取り組みや成功事例をご提案いたします。</span>
              </div>
            </div>
          </div>
          <div className="arrowWrapper">
            <div className="arrow">
              <img src={ArrowPng} />
            </div>
          </div>
          <div className="step">
            <div className="numberWrapper">
              <div className="numberLabel">
                <span>STEP</span>
              </div>
              <div className="number">
                <span>03</span>
              </div>
            </div>
            <div className="descriptionWrapper">
              <div className="descriptionTitle">
                <span>ご契約</span>
              </div>
              <div className="description">
                <span>電子契約または、書面契約にて契約締結をさせていただきます。</span>
              </div>
            </div>
          </div>
          <div className="arrowWrapper">
            <div className="arrow">
              <img src={ArrowPng} />
            </div>
          </div>
          <div className="step">
            <div className="numberWrapper">
              <div className="numberLabel">
                <span>STEP</span>
              </div>
              <div className="number">
                <span>04</span>
              </div>
            </div>
            <div className="descriptionWrapper">
              <div className="descriptionTitle">
                <span>お取り組み開始</span>
              </div>
              <div className="description">
                <span>
                  当社で専属のコンサルタントをアサインし、御社向けのキックオフを実施し、取り組みをスタートします。
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .container {
          align-items: center;
          background: ${colors.brandGradient};
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 320px;
        }
        .titleWrapper {
          align-items: center;
          display: flex;
          gap: 24px;
          justify-content: center;
          max-width: 1100px;
          width: 100%;
        }
        .divider {
          width: 300px;
        }
        .title {
          color: ${colors.white};
          font-size: 26px;
          font-weight: bold;
          text-align: center;
        }
        .steps {
          display: flex;
          align-items: center;
          max-width: 1100px;
          width: 100%;
          margin-top: 32px;
        }
        .step {
          display: flex;
          gap: 16px;
          padding: 0 16px;
          width: 270px;
          height: 100%;
        }
        .numberWrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          line-height: 1;
        }
        .numberLabel {
          color: ${colors.white};
          font-size: 12px;
        }
        .number {
          color: ${colors.white};
          font-size: 28px;
          font-weight: bold;
        }
        .descriptionWrapper {
          display: flex;
          flex-direction: column;
        }
        .descriptionTitle {
          color: ${colors.white};
          font-size: 14px;
          font-weight: bold;
        }
        .description {
          color: ${colors.white};
          font-size: 12px;
        }
        @media (${breakPoints.pc}) {
          .titleBr {
            display: none;
          }
        }
        @media (min-width: 1000px) and (max-width: 1200px) {
          .divider {
            width: 140px;
            background-color: ${colors.white};
            border: none;
            height: 1px;
          }
        }
        @media (${breakPoints.tablet}) {
          .container {
            height: 400px;
          }
          .divider {
            width: 140px;
            background-color: ${colors.white};
            border: none;
            height: 1px;
          }
        }
        @media (${breakPoints.sp}) {
          .container {
            height: auto;
            padding: 48px 20px;
          }
          .divider {
            width: 40px;
            background-color: ${colors.white};
            border: none;
            height: 1px;
          }
          .title {
            font-size: 20px;
          }
          .steps {
            flex-direction: column;
            width: 100%;
            gap: 24px;
          }
          .step {
            width: 100%;
          }
          .arrow {
            display: flex;
            align-items: center;
            transform: rotate(90deg);
            height: 24px;
          }
        }
      `}</style>
    </>
  );
};
