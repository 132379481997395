import React from "react";
import { colors } from "~/utils/colors";
import { breakPoints, siteWidth } from "~/utils/variables";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

type Props = {};

export const LpRpo01PageCasesOnTop: React.FC<Props> = () => {
  const { caseCaS, caseOmron, caseFindy } = useStaticQuery<GatsbyTypes.LpRpo01PageCasesOnTopQuery>(graphql`
    query LpRpo01PageCasesOnTop {
      caseCaS: contentfulCase(contentful_id: { eq: "4oDH3961iSvtSbWCVxOnBT" }) {
        id
        contentful_id
        ogpImage {
          gatsbyImageData(aspectRatio: 1.618, width: 600)
        }
        effect
        company {
          id
          name
          industry
          employees
          employmentType
        }
      }
      caseOmron: contentfulCase(contentful_id: { eq: "5eSQdSCQulCzyMNSYgPoIT" }) {
        id
        contentful_id
        ogpImage {
          gatsbyImageData(aspectRatio: 1.618, width: 600)
        }
        effect
        company {
          id
          name
          industry
          employees
          employmentType
        }
      }
      caseFindy: contentfulCase(contentful_id: { eq: "6WkJwT5oM2DNAdWxVJiTL4" }) {
        id
        contentful_id
        ogpImage {
          gatsbyImageData(aspectRatio: 1.618, width: 600)
        }
        effect
        company {
          id
          name
          industry
          employees
          employmentType
        }
      }
    }
  `);

  const caseCards = [caseCaS, caseOmron, caseFindy];

  return (
    <>
      <div id="cases" className="casesSession" />
      <div className="container">
        <div className="sectionLabelWrapper">
          <span className="sectionLabel">Cases</span>
        </div>
        <div className="titleWrapper">
          <span className="title">導入事例のご紹介</span>
        </div>
        <div className="casesWrapper">
          <div className="cases">
            {caseCards.map((caseCard) => {
              if (!caseCard) return;
              return (
                <div key={caseCard.id} className="card">
                  <div className="case">
                    <div className="imageSection">
                      {caseCard.ogpImage?.gatsbyImageData && (
                        <GatsbyImage image={caseCard.ogpImage.gatsbyImageData} alt="" />
                      )}
                    </div>
                    <div className="infoSection">
                      <div className="name">{caseCard.company?.name}</div>
                      <div className="infos">
                        <div className="info">
                          <div className="infoLabel">業種</div>
                          <div className="infoValue">{caseCard.company?.industry}</div>
                        </div>
                        <div className="info">
                          <div className="infoLabel">規模</div>
                          <div className="infoValue">{caseCard.company?.employees}</div>
                        </div>
                        <div className="info">
                          <div className="infoLabel">採用職種</div>
                          <div className="infoValue">{caseCard.company?.employmentType}</div>
                        </div>
                      </div>
                    </div>
                    <div className="effectSection">
                      <div className="effectLabel">導入効果</div>
                      <ul className="effectTexts">
                        {caseCard?.effect?.map((effect) => (
                          <li className="effectText">{effect}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <style jsx>{`
        .casesSession {
          content: "";
          display: block;
          height: 80px;
          margin-top: -80px;
          visibility: hidden;
        }
        .container {
          padding: 48px 24px;
          background-color: ${colors.white};
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
        }
        .sectionLabel {
          color: ${colors.brand};
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 1.75px;
        }
        .title {
          font-size: 32px;
          font-weight: bold;
          letter-spacing: 2px;
          background: radial-gradient(
              circle at calc(100% - 4px) calc(100% - 4px),
              ${colors.subOrange} 4px,
              transparent 4px
            ),
            radial-gradient(circle at 4px calc(100% - 4px), ${colors.subOrange} 4px, transparent 4px),
            linear-gradient(to bottom, ${colors.white} calc(100% - 8px), transparent calc(100% - 8px)),
            linear-gradient(
              to right,
              transparent 4px,
              ${colors.subOrange} 4px,
              ${colors.subOrange} calc(100% - 4px),
              transparent calc(100% - 4px)
            );
        }
        .casesWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 0 20px;
          margin-top: 48px;
        }
        .cases {
          display: flex;
          gap: 24px;
          max-width: ${siteWidth.default};
        }
        .case {
          border-radius: 8px;
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
          overflow: hidden;
        }
        .imageSection {
          width: 340px;
          height: 200px;
        }
        .infoSection {
          margin: 24px 24px 0;
          padding-bottom: 12px;
          border-bottom: 1px dotted ${colors.border};
        }
        .name {
          font-size: 14px;
          font-weight: bold;
        }
        .infos {
          margin-top: 16px;
        }
        .info {
          display: flex;
          align-items: center;
        }
        .infoLabel {
          font-weight: bold;
          font-size: 12px;
          width: 62px;
          color: ${colors.darkerText};
        }
        .infoValue {
          font-size: 12px;
          font-weight: 500;
        }
        .effectSection {
          margin: 12px 24px 24px;
        }
        .effectLabel {
          font-size: 12px;
          color: ${colors.orange};
          font-weight: bold;
        }
        .effectTexts {
          padding: 8px;
          border-radius: 4px;
          background-color: ${colors.lightOrange};
          height: 140px;
          line-height: 20px;
        }
        .effectText {
          font-size: 12px;
          list-style-position: inside;
          position: relative;
          padding-left: 20px;
        }
        li:before {
          content: "・";
          left: 0;
          position: absolute;
          font-weight: bold;
        }
        @media (min-width: 1000px) and (max-width: 1200px) {
          .cases {
            flex-direction: column;
            width: 340px;
          }
        }
        @media (${breakPoints.tablet}) {
          .cases {
            flex-direction: column;
            width: 340px;
          }
        }
        @media (${breakPoints.sp}) {
          .cases {
            flex-direction: column;
          }
          .titleWrapper {
            text-align: center;
          }
          .title {
            font-size: 28px;
            background: linear-gradient(transparent 70%, ${colors.subOrange} 50%);
          }
        }
      `}</style>
    </>
  );
};
